import React from "react";
import { withRouter } from "react-router-dom";
import { BehaviorSubject, timer } from "rxjs";
import {
  getResources,
  requestForAccess,
  getMyRequests,
  searchByPath,
  getPath,
  getInfo,
} from "../services/api.service";
import { Table, Button, Popover, message, Layout, Select } from "antd";
import userService from "./../services/user.service";
import {
  ArrowRightOutlined,
  ArrowLeftOutlined,
  FileTwoTone,
  FolderTwoTone,
  DoubleRightOutlined,
  DoubleLeftOutlined,
  LogoutOutlined,
  LinkOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { debounce } from "rxjs/operators";

const { Option } = Select;

// const {Title} = Typography;

const { Header } = Layout;

let textColor = {
  Pending: "blue",
  Rejected: "red",
  Approved: "green",
};

const getText = (text) => {
  return (
    <span style={{ fontWeight: 600, color: textColor[text] }}>{text}</span>
  );
};

const accessLevels = [
  {
    display: "Guest",
    value: 10,
  },
  {
    display: "Reporter",
    value: 20,
  },
  {
    display: "Developer",
    value: 30,
  },
  {
    display: "Maintainer",
    value: 40,
  },
  {
    display: "Owner",
    value: 50,
  },
];

const selectableAccessLevels = [
  {
    display: "Guest",
    value: 10,
  },
  {
    display: "Reporter",
    value: 20,
  },
  {
    display: "Developer",
    value: 30,
  },
  {
    display: "Maintainer",
    value: 40,
  },
];

class HomePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      loading: false,
      maxPage: 0,
      openRequestList: false,
      requestTableLoading: true,
      myRequests: [],
      pathSearch: "",
      searchResults: [],
      resourceName: "Path",
      loadingSearch: false,
    };

    this.searchSub = new BehaviorSubject("");
    this.searchSubDebounced = this.searchSub.pipe(debounce(() => timer(1000)));

    this.PathSub = new BehaviorSubject({
      path: this.props.location.pathname,
      page: parseInt(
        new URLSearchParams(this.props.location.search).get("page") || 1
      ),
    });

    this.props.history.listen((location, action) => {
      //console.log(location)
      this.PathSub.next({
        page: parseInt(new URLSearchParams(location.search).get("page") || 1),
        path: location.pathname,
      });
    });
  }

  // routeToDest(id) {
  //     getPath(id).subscribe(
  //         (rs) => {
  //             let newPath = "/" + rs.data.payload.reverse().join('/')
  //             this.props.history.push(newPath)
  //         },
  //         (err) => {
  //             message.error({content: err.message, duration: 2});
  //         }
  //     )
  // }

  routeToDest(id) {
    getPath(id).subscribe(
      (rs) => {
        try {
          let newPath = "/" + id;
          this.props.history.push(newPath);
        } catch (error) {
          console.log(error);
        }
      },
      (err) => {
        message.error({ content: err.message, duration: 2 });
      }
    );
  }

  columns = [
    {
      title: () => {
        return (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {this.state.resourceName}
            <Select
              size="small"
              style={{ width: "400px" }}
              placeholder="Search by project path"
              onSearch={(e) => {
                this.searchSub.next(e);
              }}
              onChange={(e) => {
                this.routeToDest(e);
              }}
              showSearch
              loading={this.state.loadingSearch}
              filterOption={(input, option) => true}
              value={null}
            >
              {this.state.searchResults.map((e, idx) => (
                <Option key={idx} value={e.id}>
                  {e.path}
                </Option>
              ))}
            </Select>
          </div>
        );
      },
      dataIndex: "path",
      key: "path",
      render: (value, record) => {
        //console.log(record)
        if (record.isGroup)
          return (
            <div>
              <FolderTwoTone />{" "}
              <span style={{ color: "#1890ff", cursor: "pointer" }}>
                {value}
              </span>{" "}
              <LinkOutlined
                href={record.web_url}
                target={record.web_url}
                onClick={(e) => e.stopPropagation()}
              />{" "}
            </div>
          );
        return (
          <div>
            <FileTwoTone /> <span>{value}</span>{" "}
            <LinkOutlined
              href={record.web_url}
              target={record.web_url}
              onClick={(e) => e.stopPropagation()}
            />
          </div>
        );
      },
    },
    {
      title: "Actions",
      render: (_, record) => {
        if (!record.permission)
          return (
            <Popover content={this.selectAccessLevel(record)}>
              <Button onClick={(e) => e.stopPropagation()}>
                Request for access
              </Button>
            </Popover>
          );
        else {
          if (record.permission.active) {
            return (
              <div>
                <Popover content={this.selectAccessLevel(record)}>
                  <Button onClick={(e) => e.stopPropagation()}>
                    Request for access
                  </Button>
                  <span style={{ marginLeft: "15px", fontWeight: 600 }}>
                    {"You are already " +
                      (
                        accessLevels.find(
                          (v) => record.permission.accessLevel === v.value
                        ) || { display: "Unknown" }
                      ).display}
                  </span>
                </Popover>
              </div>
            );
          } else {
            return (
              <div>
                <Popover content={this.selectAccessLevel(record)}>
                  <Button onClick={(e) => e.stopPropagation()}>
                    Request for access
                  </Button>
                  <span style={{ marginLeft: "15px", fontWeight: 600 }}>
                    {"You requested for " +
                      (
                        accessLevels.find(
                          (v) => record.permission.accessLevel === v.value
                        ) || { display: "Unknown" }
                      ).display}
                  </span>
                </Popover>
              </div>
            );
          }
        }
      },
    },
  ];
  requestColumns = [
    {
      title: "Target",
      dataIndex: "targetName",
    },
    {
      title: "Access Level",
      dataIndex: "accessLevel",
      render: (value, record) => {
        return accessLevels.find((e) => e.value === value).display;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      render: getText,
    },
  ];

  selectAccessLevel = (record) => {
    return (
      <div style={{ display: "flex" }}>
        {selectableAccessLevels.map((el) => {
          return (
            <Button
              key={el.value}
              onClick={(e) => {
                e.stopPropagation();
                this.requestForAccess(record, el.value);
              }}
            >
              {el.display}
            </Button>
          );
        })}
      </div>
    );
  };

  requestForAccess(target, accessLevel) {
    //console.log("Im here")
    //console.log({target, accessLevel});
    let key = "creating_request";
    message.loading({ content: "Creating request...", key: key });

    requestForAccess(target, accessLevel).subscribe(
      (rs) => {
        message.success({
          content: "Create request successfully",
          key,
          duration: 2,
        });
      },
      (err) => {
        message.error({ content: "Failed: " + err.message, key, duration: 2 });
      }
    );
  }

  componentDidMount() {
    this.setState({
      pathSearch: "",
    });
    let self = this;
    this.searchListen = this.searchSubDebounced.subscribe((value) => {
      if (value.length) {
        if (self.searchRequest) self.searchRequest.unsubscribe();
        this.setState({
          loadingSearch: true,
        });
        const groupID = this.PathSub["_value"].path.split("/").at(-1);

        self.searchRequest = searchByPath(groupID, value).subscribe(
          (rs) => {
            this.setState({
              searchResults: rs.data.payload,
              loadingSearch: false,
            });
          },
          (err) => {
            console.error(err.message);
            this.setState({
              loadingSearch: false,
            });
          }
        );
      }
    });
    this.unlisten = this.PathSub.subscribe((value) => {
      let currentID = value.path.split("/").pop();
      this.setState({
        loading: true,
      });
      if (this.getResourcesRequest) this.getResourcesRequest.unsubscribe();
      if (this.getInfoRequest) this.getInfoRequest.unsubscribe();
      //   this.getResourcesRequest = getResources(currentID, value.page).subscribe(
      //     (resp) => {
      //       this.setState({
      //         data: resp.data.payload.results,
      //         loading: false,
      //         maxPage: resp.data.payload.maxPage,
      //       });
      //       if (this.getResourcesRequest) this.getResourcesRequest = null;
      //     },
      //     (err) =>
      //       message.error({ content: err.message, duration: 2, key: "401" })
      //   );
      //   this.getInfoRequest = getInfo(currentID).subscribe(
      //     (resp) => {
      //       //(resp.data)
      //       this.setState({
      //         resourceName: (resp.data.payload || { path: "Path" }).path,
      //       });
      //     },
      //     (err) =>
      //       message.error({ content: err.message, duration: 2, key: "401" })
      //   );

      //new
      function mapProducts(payload = []) {
        console.log(payload)
        return payload.map((p) => {
          if(!p || !payload.gitlabId) {
            return null
          }
          const { gitlabId, ...rest } = p;
          return { ...rest, id: gitlabId };
        }).filter(Boolean);
      }
      this.getResourcesRequest = getResources(currentID, value.page).subscribe(
        (resp) => {
          this.setState({
            data: resp.data.payload.results,
            loading: false,
            maxPage: resp.data.payload.maxPage,
          });
          if (!resp.data.payload.results.length) {
            this.getInfoRequest = getInfo(currentID).subscribe(
              (resp) => {
                //(resp.data)
                this.setState({
                 data: mapProducts([resp.data.payload]),
                  resourceName: (resp.data.payload || { path: "Path" }).path,
                });
              },
              (err) =>
                message.error({ content: err.message, duration: 2, key: "401" })
            );
          } else {
            this.getInfoRequest = getInfo(currentID).subscribe(
              (resp) => {
                //(resp.data)
                this.setState({
                  resourceName: (resp.data.payload || { path: "Path" }).path,
                });
              },
              (err) =>
                message.error({ content: err.message, duration: 2, key: "401" })
            );
          }
          if (this.getResourcesRequest) this.getResourcesRequest = null;
        },
        (err) =>
          message.error({ content: err.message, duration: 2, key: "401" })
      );
    });
  }

  componentWillUnmount() {
    if (this.unlisten) this.unlisten.unsubscribe();
    this.unlisten = null;
  }

  getRequests() {
    this.setState({
      requestTableLoading: true,
    });
    if (this.getMyRequestsProgress) this.getMyRequestsProgress.unsubscribe();
    this.getMyRequestsProgress = getMyRequests().subscribe(
      (rs) => {
        //console.log(rs.data.payload);
        this.setState({
          requestTableLoading: false,
          myRequests: rs.data.payload.sort(
            (a, b) => new Date(b.createdDate) - new Date(a.createdDate)
          ),
        });
        this.getMyRequestsProgress = null;
      },
      (err) => {
        //console.log(err.message);
        this.setState({
          requestTableLoading: false,
        });
        this.getMyRequestsProgress = null;
      }
    );
  }

  render() {
    return (
      <div className="Home">
        <Layout>
          <Header>
            <div
              style={{
                color: "white",
                fontSize: "22px",
                position: "absolute",
                left: "15px",
                userSelect: "none",
                display: "flex",
              }}
            >
              Git Access Request
              <SettingOutlined
                style={{
                  alignSelf: "center",
                  fontSize: "18px",
                  marginLeft: "10px",
                }}
                onClick={() => {
                  this.props.history.push("/admin/requests");
                }}
              />
              <LogoutOutlined
                style={{
                  alignSelf: "center",
                  fontSize: "18px",
                  marginLeft: "10px",
                }}
                onClick={() => {
                  userService.setToken(null);
                }}
              />
            </div>
            {this.state.openRequestList ? (
              <div
                style={{
                  color: "white",
                  fontSize: "22px",
                  position: "absolute",
                  right: "15px",
                  cursor: "pointer",
                  userSelect: "none",
                }}
                onClick={(e) => {
                  this.setState({
                    openRequestList: !this.state.openRequestList,
                  });
                }}
              >
                {" "}
                <span
                  style={{
                    fontSize: "18px",
                    position: "relative",
                    top: "-2px",
                  }}
                >
                  My Requests
                </span>{" "}
                <ArrowRightOutlined />{" "}
              </div>
            ) : (
              <div
                style={{
                  color: "white",
                  fontSize: "22px",
                  position: "absolute",
                  right: "15px",
                  cursor: "pointer",
                  userSelect: "none",
                }}
                onClick={(e) => {
                  this.setState({
                    openRequestList: !this.state.openRequestList,
                  });
                  this.getRequests();
                }}
              >
                {" "}
                <ArrowLeftOutlined />{" "}
                <span
                  style={{
                    fontSize: "18px",
                    position: "relative",
                    top: "-2px",
                  }}
                >
                  My Requests
                </span>{" "}
              </div>
            )}
            {/* <div style={{color: "white", fontSize: "22px", position: "absolute", right: "15px", cursor: "pointer"}} onClick={(e)=>{this.setState({openRequestList: !this.state.openRequestList})}}> {"<"} </div> */}
            {/* <div style={{color: "white", fontSize: "22px", position: "absolute", right: "15px", cursor: "pointer"}} onClick={(e)=>{this.setState({openRequestList: !this.state.openRequestList})}}> {">"} </div> */}
          </Header>
        </Layout>
        <div style={{ display: "flex" }}>
          <div className="mainContainer" style={{ flexBasis: "100%" }}>
            <Table
              columns={this.columns}
              dataSource={this.state.data}
              rowKey="id"
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    if (record.isGroup) {
                      let newPath = this.PathSub.getValue().path.endsWith("/")
                        ? this.PathSub.getValue().path + record.id
                        : this.PathSub.getValue().path + "/" + record.id;
                      this.props.history.push(newPath);
                    }
                  },
                };
              }}
              loading={this.state.loading}
              pagination={false}
            />
            <div style={{ fontSize: "16px", margin: "10px" }}>
              <DoubleLeftOutlined
                onClick={() => {
                  if (this.PathSub.getValue().page > 1) {
                    //console.log(this.PathSub.getValue())
                    let newPage = parseInt(this.PathSub.getValue().page) - 1;
                    this.props.history.replace(
                      this.PathSub.getValue().path + "?page=" + newPage
                    );
                  }
                }}
              />
              <span style={{ marginLeft: "8px", marginRight: "8px" }}>
                Page: {this.PathSub.getValue().page}/{this.state.maxPage}
              </span>
              <DoubleRightOutlined
                onClick={() => {
                  if (this.PathSub.getValue().page < this.state.maxPage) {
                    //console.log(this.PathSub.getValue())
                    let newPage = parseInt(this.PathSub.getValue().page) + 1;
                    this.props.history.replace(
                      this.PathSub.getValue().path + "?page=" + newPage
                    );
                  }
                }}
              />
            </div>
          </div>
          {this.state.openRequestList ? (
            <div style={{ flexBasis: "60%", display: "flex" }}>
              <div
                style={{
                  backgroundColor: "#001529",
                  height: "100%",
                  width: "10px",
                }}
              ></div>
              <div style={{ flexBasis: "100%" }}>
                <Table
                  rowKey="_id"
                  loading={this.state.requestTableLoading}
                  columns={this.requestColumns}
                  dataSource={this.state.myRequests}
                  pagination={{ position: ["bottomCenter"] }}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default withRouter(HomePage);
