let UserService = require("./user.service");
const axios = require("axios-observable").Axios;

const HOST_URL = process.env.REACT_APP_HOST_URL;

axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    if (error.response.data) {
      console.log(error.response);
      if (error.response.status === 401) {
        UserService.setToken(null);
        return Promise.reject(new Error(error.response.data.reason));
      }
      if (error.response.data.reason)
        return Promise.reject(new Error(error.response.data.reason));
    }
    // Do something with response error
    return Promise.reject(error);
  }
);

const makeRequest = (options) => {
  options.url = HOST_URL + options.path;

  //console.log(options.url)
  delete options.path;

  options.headers = {
    authorization: "Bearer " + UserService.TokenSub.getValue(),
  };

  return axios.request(options);
};

const getResources = (groupID, _page) => {
  let page = _page || 1;
  return makeRequest({
    path:
      `/gitlab/resources?page=` + page + (groupID ? "&groupID=" + groupID : ""),
    method: "get",
  });
};

// old
// const searchByPath = (path) => {
//   return makeRequest({
//     path: "/gitlab/search",
//     method: "post",
//     data: {
//       search: path,
//     },
//   });
// };

// new
const searchByPath = (groupID, path) => {
  return makeRequest({
    path: `/gitlab/search?groupID=${groupID}`,
    method: "post",
    data: {
      search: path,
    },
  });
};

const requestForAccess = (target, accessLevel) => {
  return makeRequest({
    path: "/gitlab/make-request",
    method: "post",
    data: {
      target: target,
      accessLevel: accessLevel,
    },
  });
};

const getUsers = () => {
  return makeRequest({
    path: "/gitlab/users",
    method: "get",
  });
};

const getAccessFromUser = (userId) => {
  return makeRequest({
    path: "/gitlab/access?id=" + userId,
    method: "get",
  });
};

const revokeUserPermission = (userId, entityId, kind) => {
  return makeRequest({
    path: "/gitlab/revoke",
    method: "delete",
    data: {
      userId: userId,
      entityId: entityId,
      kind: kind,
    },
  });
};

const getMyRequests = () => {
  return makeRequest({
    path: "/gitlab/requests",
    method: "get",
  });
};

const getPendingRequests = () => {
  return makeRequest({
    path: "/gitlab/pending-requests",
    method: "get",
  });
};

const rejectRequest = (_id) => {
  return makeRequest({
    path: "/gitlab/reject-request",
    method: "post",
    data: {
      id: _id,
    },
  });
};

const grantPermission = (data) => {
  return makeRequest({
    path: "/gitlab/grant-permission",
    method: "post",
    data: data,
  });
};

const getPath = (v) => {
  return makeRequest({
    path: "/gitlab/get-id-path",
    method: "post",
    data: {
      id: v,
    },
  });
};

const getInfo = (gitlabId) => {
  return makeRequest({
    path: "/gitlab/get-info",
    method: "post",
    data: {
      gitlabId: gitlabId,
    },
  });
};

const getUserEmailMapping = () => {
  return makeRequest({
    path: "/gitlab/user-email-mapping",
    method: "get",
  });
};

module.exports = {
  getResources,
  requestForAccess,
  getUsers,
  getAccessFromUser,
  revokeUserPermission,
  getMyRequests,
  getPendingRequests,
  rejectRequest,
  grantPermission,
  searchByPath,
  getPath,
  getInfo,
  getUserEmailMapping,
};
