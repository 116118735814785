import React from 'react';
import { withRouter } from 'react-router-dom';
//import { BehaviorSubject } from 'rxjs';
import { getUsers, getAccessFromUser, revokeUserPermission, getUserEmailMapping } from '../services/api.service';
import { Table, Button, message, Layout, Modal, Checkbox, Input } from 'antd';
import {RetweetOutlined, LogoutOutlined, ImportOutlined} from '@ant-design/icons'
import UserService from '../services/user.service';

// const {Title} = Typography;

const { Header } = Layout;


const accessLevels = [
    {
        display: "Guest",
        value: 10
    },
    {
        display: "Reporter",
        value: 20
    },
    {
        display: "Developer",
        value: 30
    },
    {
        display: "Maintainer",
        value: 40
    },
    {
        display: "Owner",
        value: 50
    }
]


class Admin extends React.Component {

    constructor(props) {
        super(props);


        this.state = {
            data: [],
            loading: false,
            modalVisibility: false,
            userSelected: false,
            filters: ['Project', 'Group'],
            modalData: [],
            modalSearch: "",
            usernameSearch: "",
            modalTitle: "User Access",
            emailMapping: []
            // maxPage: 0,
            // openRequestList: false
        }

        // this.PathSub = new BehaviorSubject({
        //     path: this.props.location.pathname,
        //     page: parseInt(new URLSearchParams(this.props.location.search).get('page') || 1)
        // })

        // this.props.history.listen((location, action) => {
        //     //console.log(location)
        //     this.PathSub.next({
        //         page: parseInt(new URLSearchParams(location.search).get('page') || 1),
        //         path: location.pathname
        //     })
        // })
    }

    columns = [
        {
            title: ()=>{
                return (
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        Username
                        <Input size="small" style={{width: "300px"}} placeholder="Search by Username, Email" onChange={e => this.setState({usernameSearch: e.target.value})}/>
                    </div>
                )
            },
            dataIndex: 'username',
            render: (value, record) => {
                return `${record.name} - ${value} ${this.getEmail(record.gitlabId)}`
            }
        },
        {
            title: 'Url',
            dataIndex: 'webUrl',
            key: 'webUrl',
            render: (value) => {
                return <a href={value} target={value} onClick={e => e.stopPropagation()}>{value}</a>
            }
        }
    ]

    getEmail(gitlabId) {
        let index = this.state.emailMapping.findIndex((el) => el.gitlabId === gitlabId)
        if (index < 0) return ""
        else return `- ${this.state.emailMapping[index].email}`
    }

    // selectAccessLevel = (record) => {
    //     return (
    //         <div style={{ display: "flex" }}>
    //             {
    //                 accessLevels.map(el=>{
    //                     return <Button key={el.value} onClick={(e)=>{e.stopPropagation(); this.requestForAccess(record, el.value);}}>{el.display}</Button>
    //                 })
    //             }
    //         </div>
    //     )
    // }


    // requestForAccess(target, accessLevel) {
    //     //console.log("Im here")
    //     console.log({target, accessLevel});
    //     let key = "creating_request"
    //     message.loading({content: "Creating request...", key: key});

    //     requestForAccess(target, accessLevel)
    //     .subscribe((rs)=>{
    //         message.success({content: "Create request successfully", key, duration: 2});
    //     }, (err)=>{
    //         message.error({content: "Failed: " + err.message, key, duration: 2});
    //     })
    // }

    componentDidMount() {
        // this.unlisten = this.PathSub.subscribe(value => {
        //     let currentID = value.path.split('/').pop()
        //     this.setState({
        //         loading: true
        //     })
        //     if (this.getResourcesRequest) this.getResourcesRequest.unsubscribe();
        //     this.getResourcesRequest = getResources(currentID, value.page).subscribe(
        //         resp => {
        //             this.setState({
        //                 data: resp.data.payload.results,
        //                 loading: false,
        //                 maxPage: resp.data.payload.maxPage
        //             });
        //             if (this.getResourcesRequest) this.getResourcesRequest = null;
        //         }
        //     )
        // })
        if (UserService.UserSub.getValue().admin) {
            this.setState({
                loading: true,
                usernameSearch: ""
            })
            getUsers().subscribe(
                (resp) => {
                    this.setState({
                        loading: false,
                        data: resp.data.payload
                    })
                },
                (err) => {
                    console.log(err.message)
                }
            )
            
            getUserEmailMapping().subscribe(
                (resp) => {
                    this.setState({
                        emailMapping: resp.data.payload
                    })
                },
                (err) => {
                    console.log(err.message)
                }
            )
            
        }

    }

    parseToTree(rawData) {
        for (let entity of rawData) {
            if (entity.ancestor) {
                let ancestor = rawData.find((value)=>value._id === entity.ancestor)
                if (ancestor) {
                    if (ancestor.children) {
                    } else {
                        ancestor.children = []
                    }
                    entity.mark = true
                    ancestor.children.push(entity)
                }
            }
        }

        return rawData.filter(e => !(e.mark))
    }

    // componentWillUnmount() {
    //     if (this.unlisten) this.unlisten();
    //     this.unlisten = null;
    // }

    revokeUserPermission(userGitlabId, entityGitlabId, kind) {
        //do revoke
        let key = "revoking_user_" + Math.ceil(Math.random() * 100000)
        message.loading({content: "Revoke user...", key: key, duration: 30});

        revokeUserPermission(userGitlabId, entityGitlabId, kind)
        .subscribe((rs)=>{
            console.log(rs)
            message.success({content: "Successfully", key, duration: 2});
            this.setState({
                modalLoading: true
            })
            getAccessFromUser(this.state.userSelected._id).subscribe(
                (resp) => {
                    this.setState({
                        modalLoading: false,
                        modalData: this.parseToTree(resp.data.payload.map(e=>{
                            if (!e.expiresAt) e.expiresAt = "Unlimited"
                            return e
                        })),
                    })
                }
            )
        }, (err)=>{
            message.error({content: "Failed: " + err.message, key, duration: 2});
        })
    }

    render() {
        let self = this
        if (!UserService.UserSub.getValue().admin) return <div>No permission for this page</div>
        return (
            <div className="AdminPage">
                <Layout>
                    <Header>
                        <div style={{color: "white", fontSize: "22px", position: "absolute", left: "15px", userSelect: "none", display: "flex"}}>
                            Git Access Controller
                            <ImportOutlined style={{alignSelf: "center", fontSize: "18px", marginLeft: "10px"}} onClick={()=>{
                                this.props.history.push("/")
                            }}/>
                            <LogoutOutlined style={{alignSelf: "center", fontSize: "18px", marginLeft: "10px"}} onClick={()=>{
                                UserService.setToken(null)
                            }}/>
                        </div>
                        <div style={{color: "white", fontSize: "26px", position: "absolute", right: "15px", cursor: "pointer"}} 
                                onClick={()=>this.props.history.push('/admin/requests')}>
                            <RetweetOutlined />
                        </div>
                    </Header>
                </Layout>
                <div style={{display: "flex"}}>
                    <div className="mainContainer" style={{flexBasis: "100%"}}>
                        <Table
                            columns={this.columns}
                            dataSource={this.state.data.filter((e)=>(e.username + "_" + this.getEmail(e.gitlabId)).includes(this.state.usernameSearch))}
                            rowKey="_id"
                            onRow={
                                (record, rowIndex) => {
                                    return {
                                        onClick: (event) => {
                                            this.setState({
                                                modalVisibility: true,
                                                modalLoading: true,
                                                userSelected: false,
                                                filters: ['Project', 'Group'],
                                                modalSearch: "",
                                                modalTitle: record.username
                                            })
                                            getAccessFromUser(record._id).subscribe(
                                                (resp) => {
                                                    this.setState({
                                                        modalLoading: false,
                                                        modalData: this.parseToTree(resp.data.payload.map(e=>{
                                                            if (!e.expiresAt) e.expiresAt = "Unlimited"
                                                            return e
                                                        })),
                                                        userSelected: record
                                                    })
                                                }
                                            )
                                        }
                                    }

                                }
                            }
                            pagination={{
                                position: ['bottomCenter']
                            }}
                            loading = {this.state.loading}
                        />
                    </div>
                    <Modal
                        title = {this.state.modalTitle}
                        visible = {this.state.modalVisibility}
                        onOk = {()=>{this.setState({modalVisibility: false})}}
                        onCancel = {()=>{this.setState({modalVisibility: false})}}
                    >   
                        <div style={{marginBottom: "5px"}}>
                            <Checkbox.Group
                                options={['Group', 'Project']}
                                value = {this.state.filters}
                                onChange={(e)=>this.setState({filters: e})}
                            />
                            <Input
                                placeholder="Search"
                                style={{ width: 300 }}
                                onChange = {(e) => this.setState({modalSearch: e.target.value.toLowerCase()})}
                            />
                        </div>
                        <Table
                            columns={[
                                {
                                    title: 'Path',
                                    dataIndex: 'path',
                                    key: 'path'
                                },
                                {
                                    title: 'Type',
                                    dataIndex: 'kind',
                                    key: 'kind'
                                },
                                {
                                    title: 'AccessLevel',
                                    dataIndex: 'accessLevel',
                                    key: 'accessLevel',
                                    render: (value) => {
                                        // console.log(value)
                                        // console.log(accessLevels)
                                        // console.log(accessLevels.find((e) => e.value == value))
                                        let found = (accessLevels.find((e) => e.value === value))
                                        if (found) return found.display
                                        return value
                                    }
                                },
                                {
                                    title: 'Expires',
                                    dataIndex: 'expiresAt',
                                    key: 'expiresAt'
                                },
                                {
                                    title: 'Action',
                                    dataIndex: 'gitlabId',
                                    render: (value, record) => {
                                        return <Button danger onClick={()=>this.revokeUserPermission(this.state.userSelected.gitlabId, value, record.kind)}>Revoke</Button>
                                    }
                                }
                            ]}
                            dataSource={this.state.modalData.filter(e => self.state.filters.map(el=>el.toLowerCase()).includes(e.kind)).filter(e=>e.path.toLowerCase().includes(this.state.modalSearch))}
                            rowKey="_id"
                            pagination={{
                                pageSize: 6,
                                position: ['bottomCenter']
                            }}
                            loading = {this.state.modalLoading}
                        />
                    </Modal>
                </div>

            </div>
        )
    }
}

export default withRouter(Admin);