// USER LOGIN SERVICE
const jwt = require('jsonwebtoken')
const BehaviorSubject = require('rxjs').BehaviorSubject
// const Observable = require('rxjs').Observable
// const map = require('rxjs/operators').map

const TokenSub = new BehaviorSubject(localStorage.getItem('token'))
const UserSub = new BehaviorSubject(localStorage.getItem('token') ? jwt.decode(localStorage.getItem('token')) : null)
// const UserSub = map(x => x ? jwt.decode(x) : null)(TokenSub)

TokenSub.subscribe(value => {
    UserSub.next(value ? jwt.decode(value) : null)
})


module.exports = {
    UserSub: UserSub,
    TokenSub: TokenSub,
    setToken(token) {
        localStorage.setItem('token', token);
        TokenSub.next(token)
    }
}
