import React from 'react';
import { withRouter } from 'react-router-dom';
import { getPendingRequests, rejectRequest, grantPermission } from '../services/api.service';
import { Table, Button, message, Layout, Modal, DatePicker, Select } from 'antd';
import { RetweetOutlined, LogoutOutlined, ImportOutlined } from '@ant-design/icons';
import UserService from '../services/user.service';

const { Option } = Select;

const { Header } = Layout;


const accessLevels = [
    {
        display: "Guest",
        value: 10
    },
    {
        display: "Reporter",
        value: 20
    },
    {
        display: "Developer",
        value: 30
    },
    {
        display: "Maintainer",
        value: 40
    },
    {
        display: "Owner",
        value: 50
    }
]


class Admin extends React.Component {

    constructor(props) {
        super(props);


        this.state = {
            requests: [],
            loading: false,
            currentApproveItem: null,
            modalVisibility: false,
            expiresDate: null,
            currentAccessLevel: null
        }
    }

    columns = [
        {
            title: 'User Email',
            dataIndex: 'userEmail',
            key: 'userEmail'
        },
        {
            title: 'Target',
            dataIndex: 'targetName',
            key: 'targetName'
        },
        {
            title: 'Access Level',
            dataIndex: 'accessLevel',
            render: (value, record) => {
                return accessLevels.find((e) => e.value === value).display
            }
        },
        {
            title: 'Actions',
            render: (_, record) => {
                return (
                    <div style={{ display: "flex" }}>
                        <div>
                            <Button type="primary" onClick={() => this.openModal(record)}>Approve</Button>
                        </div>
                        <div style={{ marginLeft: "8px" }}>
                            <Button danger onClick={() => this.reject(record)}>Reject</Button>
                        </div>
                    </div>
                )
            }
        }
    ]

    openModal(record) {
        //console.log({record})
        this.setState({
            currentApproveItem: record,
            modalVisibility: true,
            expiresDate: null,
            currentAccessLevel: record.accessLevel
        })
    }

    reject(record) {
        let key = "rejecting_request"
        message.loading({ content: "Rejecting request...", key: key });
        rejectRequest(record._id)
            .subscribe(
                (rs) => {
                    message.success({ content: "Request has been rejected successfully...", key: key });
                    this.getPendingRequests();
                },
                (err) => {
                    message.error({ content: "Failed: " + err.message, key: key });
                }
            )
    }

    componentDidMount() {
        this.getPendingRequests()
    }

    getPendingRequests() {
        this.setState({
            loading: true
        })
        getPendingRequests()
            .subscribe((rs) => {
                this.setState({
                    loading: false,
                    requests: rs.data.payload
                })
            }, (err) => {
                console.log(err.message)
                this.setState({
                    loading: false
                })
            })
    }

    grantPermission() {
        //console.log(this.state.currentApproveItem)
        let key = "grant_permission"
        message.loading({ content: "Grant permission...", key: key });

        grantPermission({
            userId: this.state.currentApproveItem.userId,
            entityId: this.state.currentApproveItem.targetId,
            kind: this.state.currentApproveItem.targetType,
            accessLevel: this.state.currentAccessLevel,
            expiresAt: this.state.expiresDate,
            idRequest: this.state.currentApproveItem._id
        })
            .subscribe(
                (rs) => {
                    //console.log("HERE")
                    message.success({ content: "Successfully grant permision to user", key: key });
                    this.getPendingRequests();
                    this.setState({
                        modalVisibility: false
                    });
                },
                (err) => {
                    message.error({ content: "Failed: " + err.message, key: key })
                }
            )
    }

    render() {
        //let self = this
        if (!UserService.UserSub.getValue().admin) return <div>No permission for this page</div>
        return (
            <div className="AdminPage">
                <Layout>
                    <Header>
                        <div style={{ color: "white", fontSize: "22px", position: "absolute", left: "15px", userSelect: "none", display: "flex" }}>
                            Git Access Request
                            <ImportOutlined style={{ alignSelf: "center", fontSize: "18px", marginLeft: "10px" }} onClick={() => {
                                this.props.history.push("/")
                            }} />
                            <LogoutOutlined style={{ alignSelf: "center", fontSize: "18px", marginLeft: "10px" }} onClick={() => {
                                UserService.setToken(null)
                            }} />
                        </div>
                        <div style={{ color: "white", fontSize: "26px", position: "absolute", right: "15px", cursor: "pointer" }}
                            onClick={() => this.props.history.push('/admin/users')}>
                            <RetweetOutlined />
                        </div>
                    </Header>
                </Layout>
                <div style={{ display: "flex" }}>
                    <div className="mainContainer" style={{ flexBasis: "100%" }}>
                        <Table
                            columns={this.columns}
                            dataSource={this.state.requests}
                            rowKey="_id"
                            loading={this.state.loading}
                            pagination={{ position: ['bottomCenter'] }}
                        />
                    </div>
                    <Modal
                        title={"Approve Request"}
                        visible={this.state.modalVisibility}
                        onOk={() => { this.grantPermission() }}
                        onCancel={() => { this.setState({ modalVisibility: false }) }}
                    >
                        <div style={{ display: "flex" }}>
                            <div style={{ flexBasis: "50%", display: "flex" }}>
                                <Select value={this.state.currentAccessLevel} onChange={(v) => this.setState({ currentAccessLevel: v })}>
                                    {
                                        accessLevels.map(e => <Option key={e.value} value={e.value}>{e.display}</Option>)
                                    }
                                </Select>
                            </div>
                            <div style={{ flexBasis: "50%", display: "flex" }}>
                                <DatePicker value={this.state.expiresDate} onChange={(date) => this.setState({ expiresDate: date })} />
                            </div>
                        </div>

                    </Modal>
                </div>

            </div>
        )
    }
}

export default withRouter(Admin);