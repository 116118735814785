import React from 'react';
import { withRouter } from 'react-router-dom';
import UserService from './../services/user.service';
import { Button } from 'antd';
import { GitlabOutlined } from '@ant-design/icons';

const HOST_URL = process.env.REACT_APP_HOST_URL

class LoginPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            error: null
        }
    }

    componentDidMount() {
        // let {accessToken} = useParams()
        // console.log(accessToken)
        let searchParams = new URLSearchParams(this.props.location.search);


        //if error
        let error = searchParams.get('error');

        if (error) {
            this.setState({
                error: "NOT_FOOBLA_EMAIL"
            });
            return;
        }

        let accessToken = searchParams.get('accessToken');

        if (accessToken) {
            UserService.setToken(accessToken);

            this.props.history.push("/");
        }
    }

    render() {
        if (this.state.error) return (
            <div className="LoginPage">
                {
                    this.state.error === "NOT_FOOBLA_EMAIL" ? <div>NOT FOOBLA EMAIL! CAN NOT LOGIN</div> : <div>Undefined Error</div>
                }
            </div>
        )
        return (
            <div className="LoginPage">
                <div className={"LoginButton"}>
                    <Button type="primary" icon={<GitlabOutlined />} size="large" href={`${HOST_URL}/auth/gitlab`}>Login with Gitlab</Button>
                </div>
            </div>
        )
    }
}

export default withRouter(LoginPage)