import React from "react";
//import logo from './logo.svg';
import "./App.css";
import { Route, Switch, BrowserRouter, Redirect } from "react-router-dom";
import Login from "./views/Login";
import Home from "./views/Home";
import Admin from "./views/Admin";
import AdminRequest from "./views/AdminRequest";
import UserService from "./services/user.service";

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: null,
    };
  }

  componentDidMount() {
    let self = this;

    UserService.UserSub.subscribe((value) => {
      self.setState({
        currentUser: value,
      });
      //self.forceUpdate();
    });
  }

  render() {
    //console.log(this.state.currentUser)
    return (
      <div className="App">
        <BrowserRouter>
          <Switch>
            <Route path="/login">
              {UserService.UserSub.getValue() ? <Redirect to="/" /> : <Login />}
            </Route>
            <Route path="/admin/users">
              {UserService.UserSub.getValue() ? (
                <Admin />
              ) : (
                <Redirect to="/login" />
              )}
            </Route>
            <Route path="/admin/requests">
              {UserService.UserSub.getValue() ? (
                <AdminRequest />
              ) : (
                <Redirect to="/login" />
              )}
            </Route>
            <Route>
              {UserService.UserSub.getValue() ? (
                <Home />
              ) : (
                <Redirect to="/login" />
              )}
            </Route>
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
